import { useEffect, useState } from 'react';
import CNFont from 'src/components/CNFont';
import PinIcon from 'src/components/Icon/PinIcon';
import { getDrawings } from 'src/real-data';
import styles from './index.module.scss';

interface IDrawing {
  title: string;
  link?: string;
  imgUrl: string;
}

const Drawing = () => {
  const [drawings, setDrawings] = useState<IDrawing[]>([]);

  useEffect(() => {
    setDrawings(getDrawings());
  }, []);

  return (
    <>
      <h3>drawing</h3>
      <p>
        This is personal gallery of drawings, as you can see, I&apos;m not
        really good at drawing things other than character, such as cat, pillow,
        but I&apos;ll try my best. My previous names were Waste Nya and{' '}
        <CNFont text="废喵" bold />, so the images below may mention them.
      </p>
      <ul>
        {drawings.map((item) => (
          <li key={item.title}>
            <a href={`#${item.title}`}>{item.title}</a>
          </li>
        ))}
      </ul>
      <div className={styles.content}>
        {drawings.map((item) => (
          <div key={item.title}>
            <h4>
              <a
                id={item.title}
                className={styles['sub-title']}
                href={`#${item.title}`}
              >
                <PinIcon />
                {item.title}
              </a>
              <a
                href={item.link}
                className={styles.link}
                target="_blank"
                rel="noreferrer"
              >
                pixiv
              </a>
            </h4>
            <img src={item.imgUrl} loading="lazy" />
          </div>
        ))}
      </div>
    </>
  );
};

export default Drawing;
