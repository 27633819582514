const CNFont = ({ text, bold }: { text: string; bold?: boolean }) => {
  return (
    <span
      style={{
        fontFamily: 'fangsong',
        fontWeight: bold ? 'bold' : 'normal',
      }}
    >
      {text}
    </span>
  );
};

export default CNFont;
