import Layout from 'src/pages/layout';
import Home from 'src/pages/home';
import MyToolKit from 'src/pages/my-toolkit';
import TechTips from 'src/pages/tech-tips';
import Drawing from 'src/pages/drawing';
import Upcoming from 'src/pages/upcoming';

export interface IRouterItem {
  name: string;
  path: string;
  component: (props: { router: IRouterItem[] }) => JSX.Element;
  children?: IRouterItem[];
}

const router: IRouterItem[] = [
  {
    component: Layout,
    path: '/',
    name: 'layout',
    children: [
      { path: '/home', name: 'home', component: Home },
      { path: '/toolkit', name: 'toolkit', component: MyToolKit },
      { path: '/tips', name: 'tips', component: TechTips },
      { path: '/drawing', name: 'drawing', component: Drawing },
      { path: '/upcoming', name: 'upcoming', component: Upcoming },
    ],
  },
];

export default router;
