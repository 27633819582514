import { useEffect } from 'react';
import { useState } from 'react';
import PinIcon from 'src/components/Icon/PinIcon';
import { getTechTips } from 'src/real-data';
import styles from './index.module.scss';

export interface Tip {
  title: string;
  __html: string;
}

const TechTips = () => {
  const [tips, setTips] = useState<Tip[]>([]);

  useEffect(() => {
    setTips(getTechTips());
  }, []);

  return (
    <>
      <h3>tech tips</h3>
      <p>
        Tech tips, kind of like notes, is used to record useful tips, my blind
        spot of knowledge at that time and so on. <br />
        This chapter is in continuously update, you can also find it in{' '}
        <a
          href="https://github.com/zixiCat/tips"
          target="_blank"
          rel="noreferrer"
        >
          github
        </a>
        .
      </p>
      <ul>
        {tips.map((item) => (
          <li key={item.title}>
            <a href={`#${item.title}`}>{item.title}</a>
          </li>
        ))}
      </ul>
      {tips.map((item) => (
        <div key={item.title}>
          <h4>
            <a
              id={item.title}
              className={styles['sub-title']}
              href={`#${item.title}`}
            >
              <PinIcon />
              {item.title}
            </a>
          </h4>
          <article className={styles.content} dangerouslySetInnerHTML={item} />
        </div>
      ))}
    </>
  );
};

export default TechTips;
