import styles from './index.module.scss';

const Home = () => {
  return (
    <>
      <h3>home page</h3>
      <div>
        <p>
          Hi, my name is zixiCat.
          <br />
          I&apos;m an amateur of digital drawing and also a software engineer or
          more specifically, a front-end engineer.
        </p>
        <h4>intro</h4>
        <img
          loading="lazy"
          width="320"
          height="320"
          src="https://img.cdn.zixicat.cc/zixiCat_20230718.jpg"
          className={styles.avatar}
        />
        <p>
          This is my personal space, here you can find something I made,
          including programming tools and drawings. And of course, I enjoy
          working on and learning about programming, if I&apos;m not coding, I
          might be drawing or in rocket league.
        </p>
      </div>
      <p>You can also find me or contact me via</p>
      <ul>
        <li>
          <a href="https://github.com/zixiCat" target="_blank" rel="noreferrer">
            GitHub
          </a>
        </li>
        <li>
          <a
            href="https://stackoverflow.com/users/11249191/zixicat"
            target="_blank"
            rel="noreferrer"
          >
            StackOverflow
          </a>
        </li>
        <li>
          <a
            href="https://www.pixiv.net/en/users/24235703"
            target="_blank"
            rel="noreferrer"
          >
            Pixiv
          </a>
        </li>
        <li>
          <a>zixicat@qq.com</a>
        </li>
      </ul>
      <p> </p>
    </>
  );
};

export default Home;
