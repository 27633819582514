export const getToolList = () => [
  {
    title: 'form-bunch',
    link: 'https://github.com/zixiCat/form-bunch#form-bunch',
    desc: `Form-bunch is a component like plugin that make it easier to write form. 
    You could add the most of components what you want to form-bunch for build various form. 
    You could also easily change the settings to get the verification or layout you want.`,
  },
  {
    title: 'config-pack',
    link: 'https://github.com/zixiCat/config-pack#config-pack',
    desc: `Config-pack, which contains some basic configurations or templates for regular use. 
    It can help you to download the config file and the remote files/folder you want from github without downloading this npm package`,
  },
  {
    title: 'mini-ps',
    link: 'https://github.com/zixiCat/mini-ps#%E8%BF%B7%E4%BD%A0ps%E5%B0%8F%E7%A8%8B%E5%BA%8F',
    desc: `It's a small and lightweight program based on uni-app framework, it can edit and generate posters in multiple aspect.`,
  },
  {
    title: 'babel-plugin-react-i18next',
    link: 'https://github.com/zixiCat/babel-plugin-react-i18next#babel-plugin-react-i18next',
    desc: `A babel plugin to help you to invoke some i18n-related translation functions globally.`,
  },
  {
    title: 'easy-create-react-context',
    link: 'https://github.com/zixiCat/easy-create-react-context#easy-create-react-context',
    desc: `The tool just encapsulated React.createContext, but it's more efficient, more convenient when compared to Reducer + Context such a combination and easier to manage the state of small modules. 
    You can think of it as mini version of MobX, but with the same rendering efficiency. 
    You can also think of it as my personal black-tech small tool.`,
  },
  {
    title: 'office-workflow',
    link: 'https://github.com/zixiCat/office-workflow#office-workflow',
    desc: 'To be continued',
  },
];

export const getTechTips = () => [
  {
    title: 'BabelJS',
    __html: `<ul>
    <li><p>When using CRA, the babel config is handled by webpack config file. 
    So when you create <code>.babelrc</code> file in the root, maybe it wouldn’t work unless you configured <code>babel-loader</code>.</p>
    </li>
    <li><p>When using babel-loader, set the option of <code>.babelrc</code> will do that:</p>
    <ul>
    <li>If true, the plugins of <code>.babelrc</code> will replace the babel-loader’s plugins, but the presets will be merged even if false.</li>
    <li>If false, the plugins of <code>.babelrc</code> will not work.</li>
    </ul>
    </li>
    <li><p>The <code>exit</code> property of babel types, its params include the code that compiled by presets and plugins.</p>
    </li>
    <li>When using <code>customize-cra</code>, to disable babel-loader cache, you can refer to this url...<a href="https://github.com/mixail-novikov/customize-cra-disable-babel-cache">ref</a></li>
    <li>JSXText() { … } is shorthand for JSXText: { enter() { … } }</li>
    </ul>`,
  },
  {
    title: 'Bash',
    __html: `<ul>
    <li>As the code snippet below, We would get all the val from <code>console.log</code><pre><code>#!/bin/bash\nVAL=$(node app.js)</code></pre>
    </li>
    <li>The different we should know between bash and shell...<a href="https://stackoverflow.com/questions/5725296/difference-between-sh-and-bash">ref</a></li>
    <li>Same as NodeJS, we can use color-code to beauty the font...<a href="https://stackoverflow.com/questions/5947742/how-to-change-the-output-color-of-echo-in-linux">ref</a><pre><code>RED='\\033[0;31m'
NC='\\033[0m' # No Color
echo -e "This is \${RED}red\${NC}"</code></pre>
    </li>
    </ul>
    `,
  },
  {
    title: 'CICD',
    __html: `<ul>
    <li>The official plugin <strong>gitlab-plugin</strong> can help you to send the pipeline result in jenkins to gitlab...<a href="https://github.com/jenkinsci/gitlab-plugin">ref</a></li>
    <li>Be aware of the <strong>General</strong> option in repository setting, where you can found some setting about pipeline from <strong>Merge requests</strong></li>
    </ul>`,
  },
  {
    title: 'Docker',
    __html: `<ul>
    <li>Seems like you should run two containers, one is jenkins, the other is sub-docker. The sub-docker would help you to run docker in jenkins in docker, and it would help to avoid the error &quot;docker not found&quot;...<a href="https://www.jenkins.io/doc/book/installing/docker/">ref</a></li>
    </ul>`,
  },
  {
    title: 'Git',
    __html: `<ul>
    <li>Use <code>git stash</code> to save code temporarily, you could restore it by using <code>git stash apply index</code> or <code>git stash pop index</code>. Of course there is <code>git stash list</code> or <code>git stash clear</code>...<a href="https://www.git-scm.com/docs/git-stash">ref</a></li>
    <li>Use <code>git reflog show</code>, and then you could recover from <code>git reset --hard</code> you had done.</li>
    <li>Use this following command line:<pre><code class="lang-bash">git ls-remote --tags --refs <span class="hljs-built_in">origin</span> | cut -f2 | xargs git <span class="hljs-built_in">push</span> <span class="hljs-built_in">origin</span> --<span class="hljs-built_in">delete</span></code></pre>
    Then your all remote tags will be removed in one go, but when you use this command line:<pre><code class="lang-bash">git <span class="hljs-keyword">tag</span> <span class="hljs-title">-l</span> | xargs -n <span class="hljs-number">1</span> git push --delete origin</code></pre>
    It will delete remote tags every 2 seconds for one, really trash! The following command line could be used to delete all local tags...<a href="https://stackoverflow.com/questions/19542301/delete-all-tags-from-a-git-repository">ref</a><pre><code class="lang-bash">git <span class="hljs-keyword">tag</span> <span class="hljs-title">| xargs</span> git <span class="hljs-keyword">tag</span> <span class="hljs-title">-d</span></code></pre>
    </li>
    <li>Use <code>git reset HEAD@{1}</code>, and you can recover your code from git-reset-hard, see more...<a href="https://stackoverflow.com/questions/5788037/recover-from-git-reset-hard">ref</a></li>
    <li>To authenticate with GitHub, use this one: <code>https://[USERNAME]:[TOKEN]@[GIT_ENTERPRISE_DOMAIN]/[ORGANIZATION]/[REPO].git</code>...<a href="https://stackoverflow.com/questions/18935539/authenticate-with-github-using-a-token?answertab=votes#tab-top">ref</a></li>
    <li>The similarities and differences between monorepo and polyrepo...<a href="https://github.com/joelparkerhenderson/monorepo_vs_polyrepo">ref</a></li>
    <li>Use <code>git config --local --get core.hookspath</code>, and then terminal will show the value.</li>
    <li><code>git commit -m &quot;subject&quot; -m &quot;body&quot; -m &quot;footer&quot;</code></li>
    <li>We can get the committed message via <code>$(cat $1)</code> in <code>commit-msg</code>, <code>prepare-commit-msg</code> of git hooks, but the message isn&#39;t accessible to <code>pre-commit</code> hook</li>
    <li>To make your committed message awesome, there are some packages to help you like <code>husky</code>, <code>commitizen</code>. You can also customize hooks files or config the hooksPath to directory you want...<a href="https://backlog.com/blog/git-commit-messages-bold-daring/">ref</a></li>
    <li><code>git commit -a/--all</code> will tell the command to automatically stage files that have been modified and deleted, but new files you have not told Git about are not affected...<a href="https://git-scm.com/docs/git-commit/en">ref</a></li>
    </ul>
    `,
  },
  {
    title: 'HTML&CSS',
    __html: `<ul>
    <li>Use <code>@font-face</code> in css file, and you can add custom font file</li>
    <li>The <code>:root</code> CSS pseudo-class can help you to declare global variables of CSS</li>
    <li>The CSS function <code>attr()</code> would be useful sometimes</li>
    <li>Using <code>pointer-events: none</code> in CSS can prevent default event of some element.</li>
    <li>To make the pages that contains <code>&lt;iframe&gt;</code> to be adaptive, there is a good way, see lxs&#39;s answer...<a href="https://stackoverflow.com/questions/166160/how-can-i-scale-the-content-of-an-iframe">ref</a></li>
    <li><code>loading</code> attribute can achieve lazy loading in <code>&lt;img&gt;</code> or <code>&lt;iframe&gt;</code>...<a href="https://developer.mozilla.org/en-US/docs/Web/Performance/Lazy_loading">ref</a></li>
    </ul>`,
  },
  {
    title: 'NativeJS',
    __html: `<ul>
    <li><code>[{a:1, b:2, c:3}] =&gt; [{b:2, c:3}]</code> by <code>arr.map({a, ...rest} =&gt; rest)</code> </li>
    <li>If you want every page has the same title when use <code>window.print()</code>, you can try to put &#39;title&#39; to the <code>&lt;thead&gt;</code>.</li>
    <li>You can use the @page CSS at-rule to modify the setting of print.</li>
    <li>About JavaScript Style, you can click <a href="https://github.com/airbnb/javascript">this</a> for more details, followings are some tips I think it&#39;s useful for me.<ul>
    <li>Use <code>const</code> for all of your references, avoid using <code>var</code> or <code>let</code>, because this can ensure that you can’t reassign your references, which can lead to bugs and difficult to comprehend code...<a href="https://github.com/airbnb/javascript#references--prefer-const">ref</a></li>
    <li>Do not use <code>Object.property</code> directly, such as <code>hasOwnProperty</code>. Because these methods may be shadowed by properties on the object in question...<a href="https://github.com/airbnb/javascript#objects--prototype-builtins">ref</a></li>
    </ul>
    </li>
    <li><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining">optional chaining ?</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator">nullish coalescing operator ??</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_nullish_assignment">logical nullish assignment ??=</a></li>
    <li>Please make good use of the second param of <code>replace</code> function, in which you can obtain the result of <code>$1</code>, <code>$2</code>, e.g.<pre><code>'a_bc1'.replace(<span class="hljs-name">/_</span>([a-z])([a-z])/g, (<span class="hljs-name">x</span>, $<span class="hljs-number">1</span>, $<span class="hljs-number">2</span>) =&gt; $<span class="hljs-number">2</span>)</code></pre>
    </li>
    <li>You can find a lot of parser tools or transform tools for AST in this site <a href="https://astexplorer.net/">astexplorer.net</a></li>
    </ul>
    `,
  },
  {
    title: 'NodeJS',
    __html: `<ul>
    <li>Some packages to beautify style of terminal.<ul>
    <li><a href="https://github.com/sindresorhus/cli-spinners">cli-spinners</a></li>
    <li><a href="https://github.com/chalk/u">chalk</a></li>
    <li><a href="https://github.com/visionmedia/node-progress">progress</a></li>
    <li><a href="https://github.com/pitaj/multi-progress">multi-progress</a></li>
    <li><a href="https://github.com/npm/gauge">gauge</a></li>
    <li><a href="https://github.com/sindresorhus/boxen">boxen</a></li>
    <li><a href="https://github.com/cenk1cenk2/listr2">listr2</a></li>
    <li><a href="https://github.com/npkgz/cli-progress">cli-progress</a></li>
    <li><a href="https://github.com/sindresorhus/ora">ora</a></li>
    </ul>
    </li>
    <li>We can use <code>process.stderr.write(&#39;\\x1B[?25h&#39;)</code> to show terminal cursor or use <code>process.stderr.write(&#39;\\x1B[?25l&#39;)</code> to hide terminal cursor, that&#39;s really useful when it come to beautifying style.</li>
    <li>The <code>package.json</code> have some really useful fields that we might use in making npm package, for example, the <code>bin</code> field would help you to execute some command via npx, and the <code>prepare</code>, <code>preinstall</code> in <a href="https://docs.npmjs.com/cli/v7/using-npm/scripts"><code>script</code></a> filed would help you to run a script when package is installed...see <a href="https://docs.npmjs.com/cli/v7/configuring-npm/package-json">docs</a> for more details.</li>
    <li>Sometimes you should use the <code>encoding</code> option that it would help you to print what you want, e.g. <code>child_process.execSync(&quot;npm info grunt version&quot;, { encoding: &#39;UTF-8&#39;})</code>.</li>
    <li>In order to interact with terminal, there are some APIs and modules we can use, e.g. <code>process.openStdin</code>, <code>readline</code>...<a href="https://stackoverflow.com/questions/5947742/how-to-change-the-output-color-of-echo-in-linux">ref1</a>...<a href="https://github.com/philipszdavido/cli-select-opts/blob/master/select.js">ref2</a></li>
    <li>Change color of console.log without package, we can use <code>console.log(&#39;\\x1b[32m%s\\x1b[0m&#39;, &#39;green&#39;)</code> or <code>console.log(&#39;\\x1b[31m&#39;, &#39;red&#39; ,&#39;\\x1b[0m&#39;)</code>...<a href="https://stackoverflow.com/questions/9781218/how-to-change-node-jss-console-font-color">ref</a></li>
    <li>We can use <code>git stash</code> to run bash <code>scripts</code> filed in <code>package.json</code> via using <code>bash fileName</code>...<a href="https://awsm.page/nodejs/run-shell-scripts-using-npm-script/">ref</a></li>
    <li>Use <code>--eval</code>/<code>-e</code>, and then we can run script only in command line...<a href="https://nodejs.org/docs/latest-v15.x/api/cli.html#cli_e_eval_script">ref</a></li>
    <li>When the <code>main</code> field in <code>package.json</code> is set,  use <code>node .</code> or <code>require</code>  will involve the field as default.</li>
    <li>Please pay attention to the usage of <code>process.cwd()</code> and <code>__dirname</code>, especially when using <code>path</code>, <code>npx</code>.</li>
    </ul>
    `,
  },
  {
    title: 'ReactJS',
    __html: `<ul>
    <li><p>If you would like to use .svg files in React Component, you can do it as following...<a href="https://stackoverflow.com/questions/42296499/how-to-display-svg-icons-svg-files-in-ui-using-react-component">ref</a></p>
    <pre><code><span class="hljs-keyword">import</span> { ReactComponent <span class="hljs-keyword">as</span> YourSvg } <span class="hljs-keyword">from</span> <span class="hljs-string">'./your-svg.svg'</span>;
      <span class="hljs-keyword">const</span> App = <span class="hljs-function"><span class="hljs-params">()</span> =&gt;</span> (
        <span class="xml"><span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">YourSvg</span> /&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span></span>
      );</code></pre>
    </li>
    <li>We should not wrap every prop with useCallback or useMemo...<a href="https://stackoverflow.com/questions/55310682/should-i-wrap-every-prop-with-usecallback-or-usememo-when-to-use-this-hooks">ref</a></li>
    <li>The difference between <code>useMutationEffect</code> and <code>useLayoutEffect</code>...<a href="https://stackoverflow.com/questions/53513872/react-hooks-what-is-the-difference-between-usemutationeffect-and-uselayoutef">ref</a></li>
    </ul>
    `,
  },
  {
    title: 'TypeScript',
    __html: `<ul>
    <li>You can the following formula to transform union type to intersection type, but don&#39;t think it&#39;s possible when in the opposite direction.<pre><code class="lang-typescript"><span class="hljs-built_in">type</span> UnionToIntersection&lt;U&gt; = <span class="hljs-function"><span class="hljs-params">(U <span class="hljs-keyword">extends</span> any ? (k: U)=&gt;void : never)</span> extends (<span class="hljs-params">(k: infer I)</span>=&gt;</span>void) ? I : never</code></pre>
    </li>
    <li>You can extract the property name which value is of function type<pre><code class="lang-typescript"><span class="hljs-keyword">type</span> <span class="hljs-type">TFnPropertyNames</span>&lt;<span class="hljs-type">T</span>&gt; = { [<span class="hljs-type">K</span> in keyof <span class="hljs-type">T</span>]: <span class="hljs-type">T</span>[<span class="hljs-type">K</span>] extends <span class="hljs-type">Function</span> ? <span class="hljs-type">K</span> : never }[keyof <span class="hljs-type">T</span>]</code></pre>
    </li>
    <li>If you&#39;d like to set the type of class anyway, you can try this: <code>type Class&lt;T = any&gt; = new (...args: any[]) =&gt; T;</code></li>
    <li>If you&#39;d like to the interface that require only one of two property, try this:<pre><code class="lang-typescript"><span class="hljs-class"><span class="hljs-keyword">type</span> <span class="hljs-title">RequireOnlyOne&lt;T</span>, <span class="hljs-title">Keys</span> <span class="hljs-keyword">extends</span> <span class="hljs-title">keyof</span> <span class="hljs-title">T</span> </span>= keyof <span class="hljs-type">T</span>&gt; = <span class="hljs-type">Pick</span>&lt;
      <span class="hljs-type">T</span>,
      <span class="hljs-type">Exclude</span>&lt;keyof <span class="hljs-type">T</span>, <span class="hljs-type">Keys</span>&gt;
    &gt; &amp;
      {
        [<span class="hljs-type">K</span> in <span class="hljs-type">Keys</span>]-?: <span class="hljs-type">Required</span>&lt;<span class="hljs-type">Pick</span>&lt;<span class="hljs-type">T</span>, <span class="hljs-type">K</span>&gt;&gt; &amp;
          <span class="hljs-type">Partial</span>&lt;<span class="hljs-type">Record</span>&lt;<span class="hljs-type">Exclude</span>&lt;<span class="hljs-type">Keys</span>, <span class="hljs-type">K</span>&gt;, undefined&gt;&gt;;
      }[<span class="hljs-type">Keys</span>];</code></pre>
    </li>
    <li><p>6 ways to narrow types in TypeScript...<a href="https://www.carlrippon.com/6-ways-to-narrow-types-in-typescript/">ref</a></p>
    <ul>
    <li>using a conditional value check to remove <code>null</code> and <code>undefined</code> from a type</li>
    <li>using <code>typeof</code> function to narrow the type to primitive types</li>
    <li>using <code>instanceof</code> function to narrow the type to class types</li>
    <li>using <code>in</code> function to determine if the type is in the object</li>
    <li>using type predicates to narrow to some complex type you want</li>
    <li>using <code>assert</code> function to narrow to some complex type if you wan throw some errors</li>
    </ul>
    </li>
    <li><p>an excellent way to simplify the type <code>TUnitTransform</code>, it&#39;s related to several key points...<a href="https://stackoverflow.com/questions/65850619/is-there-an-function-to-simplify-the-following-union-type-tunittransform">ref</a></p>
    <pre><code class="lang-typescript"><span class="hljs-keyword">type</span> TUnitTransform = ((...rest: [<span class="hljs-keyword">number</span>]) =&gt; [<span class="hljs-keyword">number</span>]) &amp;
      ((...rest: [<span class="hljs-keyword">number</span>, <span class="hljs-keyword">number</span>]) =&gt; [<span class="hljs-keyword">number</span>, <span class="hljs-keyword">number</span>]) &amp;
      ((...rest: [<span class="hljs-keyword">number</span>, <span class="hljs-keyword">number</span>, <span class="hljs-keyword">number</span>]) =&gt; [<span class="hljs-keyword">number</span>, <span class="hljs-keyword">number</span>, <span class="hljs-keyword">number</span>]) &amp;
      ......
    
    <span class="hljs-keyword">type</span> TUnitTransform = 
      &lt;T <span class="hljs-keyword">extends</span> [<span class="hljs-keyword">number</span>, ...<span class="hljs-keyword">number</span>[]]&gt;(...rest: [...T]) =&gt; { [K <span class="hljs-keyword">in</span> keyof T]: <span class="hljs-keyword">number</span>; }</code></pre>
    </li>
    </ul>
    `,
  },
  {
    title: 'VueJS',
    __html: '<ul>To be continued</ul>',
  },
  {
    title: 'WebPackJS',
    __html: `<ul>
    <li>Use <code>speed-measure-webpack-plugin</code> to measure building speed</li>
    <li>Do not create an entry for vendors or other stuff that is not the starting point of execution, <code>optimization.splitChunks</code> helps you...<a href="https://webpack.js.org/concepts/entry-points/#entrydescription-object">ref</a></li>
    </ul>
    `,
  },
  {
    title: 'Other',
    __html: `<ul>
    <li>August 6, 1991: Sir Tim Berners-Lee at CERN launched <a href="http://info.cern.ch/hypertext/WWW/TheProject.html">the first website</a></li>
    <li>SHA--Secure Hash Algorithm</li>
    <li>When we using &#39;ping google.com&#39; with ssr running, it would time out, but using vpn can work sometimes. that&#39;s as the socks proxy of ssr is based on session layer or transport layer, while vpn is based on network layer, which is the same as <code>ping operating</code>...<a href="https://my.oschina.net/u/4330033/blog/4532164">ref</a></li>
    <li><a href="https://github.com/AlDanial/cloc">cloc</a> can help you count all the lines of code.</li>
    </ul>
    `,
  },
];

export const getDrawings = () => [
  {
    title: 'Darling',
    imgUrl: 'http://img.cdn.zixicat.cc/85659984_p0.png',
    link: 'https://www.pixiv.net/en/artworks/85659984',
  },
  {
    title: 'No Title',
    imgUrl: 'http://img.cdn.zixicat.cc/82936694_p0.jpg',
    link: 'https://www.pixiv.net/en/artworks/82936694',
  },
  {
    title: 'miHoYo Hakase',
    imgUrl: 'http://img.cdn.zixicat.cc/80937213_p0.jpg',
    link: 'https://www.pixiv.net/en/artworks/80937213',
  },
  {
    title: 'Game Prototype(maybe) 1',
    imgUrl: 'http://img.cdn.zixicat.cc/75519232_p0.jpg',
    link: 'https://www.pixiv.net/en/artworks/75519232',
  },
  {
    title: 'Game Prototype(maybe) 2',
    imgUrl: 'http://img.cdn.zixicat.cc/75519232_p1.jpg',
    link: 'https://www.pixiv.net/en/artworks/75519232',
  },
  {
    title: 'Game Prototype(maybe) 3',
    imgUrl: 'http://img.cdn.zixicat.cc/75519232_p2.jpg',
    link: 'https://www.pixiv.net/en/artworks/75519232',
  },
  {
    title: 'Merpeople',
    imgUrl: 'http://img.cdn.zixicat.cc/75254591_p0.jpg',
    link: 'https://www.pixiv.net/en/artworks/75254591',
  },
  {
    title: 'Dragon Turtle',
    imgUrl: 'http://img.cdn.zixicat.cc/75119642_p0.jpg',
    link: 'https://www.pixiv.net/en/artworks/75119642',
  },
  {
    title: 'Sunset Girl',
    imgUrl: 'http://img.cdn.zixicat.cc/74542944_p0.jpg',
    link: 'https://www.pixiv.net/en/artworks/74542944',
  },
];
