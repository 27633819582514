import { useEffect } from 'react';
import { useState } from 'react';
import PinIcon from 'src/components/Icon/PinIcon';
import { getToolList } from 'src/real-data';
import styles from './index.module.scss';
export interface Tool {
  title: string;
  link: string;
  desc: string;
}

const MyToolKit = () => {
  const [toolList, setToolList] = useState<Tool[]>([]);
  useEffect(() => {
    setToolList(getToolList());
  }, []);
  return (
    <>
      <h3>my toolkit</h3>
      <p>
        These are tools I developed myself to help with work or personal
        development.
      </p>
      <ul>
        {toolList.map((item) => (
          <li key={item.title}>
            <a href={`#${item.title}`}>{item.title}</a>
          </li>
        ))}
      </ul>
      <div className={styles.content}>
        {toolList.map((item) => (
          <div key={item.title}>
            <h4>
              <a
                id={item.title}
                className={styles['sub-title']}
                href={`#${item.title}`}
              >
                <PinIcon />
                {item.title}
              </a>
              <a
                href={item.link}
                className={styles.link}
                target="_blank"
                rel="noreferrer"
              >
                github
              </a>
            </h4>
            <p>{item.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyToolKit;
