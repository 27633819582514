const Upcoming = () => {
  return (
    <>
      <h3>upcoming</h3>
      <h4>a new tool: office-workflow</h4>
      <p>
        A JavaScript tool, which can help you automatically convert
        &quot;.docx&quot; file into &quot;.html&quot; file and convert
        &quot;.html&quot; file into &quot;.docx&quot; file with high fidelity,
        yeah, with high fidelity. And this feature may be just one of its
        features. You can track its completion{' '}
        <a
          href="https://github.com/zixiCat/office-workflow"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <h4>more scaffold templates</h4>
      <p>
        More templates will be added, especially scaffold templates to{' '}
        <a
          href="https://github.com/zixiCat/config-pack"
          target="_blank"
          rel="noreferrer"
        >
          config-pack
        </a>
        .
      </p>
      <h4>maybe a new drawing</h4>
      <p>I will make a higher quality drawing in the near future.</p>
    </>
  );
};

export default Upcoming;
