import { useState } from 'react';
import RouterView from 'src/components/RouterView';
import { IRouterItem } from 'src/router';
import styles from './index.module.scss';
import { useHistory } from 'react-router-dom';
import Home from 'src/pages/home';
import { useEffect } from 'react';
import CNFont from 'src/components/CNFont';

interface LayoutProps {
  router: IRouterItem[];
}

interface CatalogueItem {
  name: string;
  link: string;
}

const Layout = (props: LayoutProps) => {
  const [isHome, setIsHome] = useState(true);
  const history = useHistory();
  const [catalogue] = useState<CatalogueItem[]>([
    { name: 'Home Page', link: '/' },
    { name: 'My Toolkit', link: '/toolkit' },
    { name: 'Tech Tips', link: '/tips' },
    { name: 'Drawing', link: '/drawing' },
    { name: 'Upcoming', link: '/upcoming' },
  ]);
  const [activeIndex, setActiveIndex] = useState(
    catalogue.findIndex((i) => i.link === history.location.pathname)
  );

  const [hasTopBtn, setHasTopBtn] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      document.documentElement.scrollTop > 20
        ? setHasTopBtn(true)
        : setHasTopBtn(false);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    setIsHome(history.location.pathname === '/');
    const listen = history.listen((location) => {
      setIsHome(location.pathname === '/');
    });
    return function cleanup() {
      listen();
    };
  }, [history]);

  return (
    <>
      <div className={`row ${styles.site}`}>
        <div className={`sm-12 md-8 col ${styles.main}`}>
          <div className="paper">
            {isHome ? <Home /> : <RouterView router={props.router} />}
          </div>
          {hasTopBtn && (
            <div className={styles['top-btn-wrapper']}>
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Top
              </button>
            </div>
          )}
        </div>
        <div className="sm-12 md-4 col">
          <div className="paper">
            <h3 className={styles.title}>catalogue</h3>
            {catalogue.map((item, index) => (
              <div className="collapsible" key={item.name}>
                <label
                  htmlFor="collapsible-components"
                  className={index === activeIndex ? styles.active : ''}
                  onClick={() => {
                    history.push(item.link);
                    window.scrollTo(0, 0);
                    setActiveIndex(index);
                  }}
                >
                  {item.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p className={styles.record}>
        <span
          onClick={() => {
            window.open(
              'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=45042202000065'
            );
          }}
        >
          <CNFont text="桂公网安备" />
          45042202000065
          <CNFont text="号" />
        </span>
        &nbsp;&nbsp;
        <span
          onClick={() => {
            window.open('https://beian.miit.gov.cn/');
          }}
        >
          <CNFont text="桂" />
          ICP
          <CNFont text="备" />
          2021007169
          <CNFont text="号" />
          -1
        </span>
      </p>
    </>
  );
};

export default Layout;
